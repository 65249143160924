<template>
    <transition name="scale">
        <div class="circlePopup" v-if="cperson != null" v-click-away="onClickAway">
            <button @click="cperson = null" class="close"></button>
            <div>
                <div>
                    <div class="circlePopup__text" v-html="`${cperson.contentText}`"></div>
                    <a class="websiteLink" :href="cperson['websiteLink']" target="_blank">{{ cperson['websiteName'] }}</a>
                    <div class="circlePopup__formats" v-if="cperson.formats">
                        <a v-for="format in cperson.formats" v-bind:key="format" :style="`background-color: ${format.color}`" :href="$url(`formats/${format.slug}`)">
                            {{ format.number }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
// @ is an alias to /src
export default {
    name: 'CirclePopup',
    components: {

    },
    data(){
        return {
            cperson: null,
            cpersonOld: null,
        }
    },
    props: {

    },
    mounted(){
    },
    methods: {
        onClickAway() {
            if((this.cperson == this.cpersonOld) || this.cpersonOld == null){
                this.cperson = null;
            }
            this.cpersonOld = null;
        },
        setCPerson(person){
            this.cpersonOld = this.cperson;
            this.cperson = person;
        }
    },
    watch: {
      cperson: function (val){
        if(val != null){
          window.$("a.button-hamburger").hide();
        }else{
          window.$("a.button-hamburger").show();
        }
      }
    }
}
</script>
