<template>
    <div v-if="$store.getters.getFormat"
    v-bind:style="[{marginLeft: ($store.getters.getFormatPosition*40)+'px'}, getMainColor($store.getters.getFormat.color)]"
    :class="['format format__' + $store.getters.getFormat['id'], status, {'isScrolled': isScrolled, 'loaded': loaded}]">

    <header>
        <Close />
        <h3 v-if="$store.getters.getFormat.title == 'Work it Out'">Work <del>it</del> Out</h3>
        <h3 v-else>{{ $store.getters.getFormat.title }}</h3>
    </header>

    <SectionAgenda />
    <SectionDescription />
    <SectionGallery />

    <div class="mobile">
      <ul :class="['menu__items', `menu__items-total-${$store.getters.getFormats.length}`]">
          <li :style="{ '--main-color': format.color }" :class="{'menu__item': true, 'menu__item-active': $store.state.formats.current == format.slug}" v-for="(format, index) in $store.getters.getFormats" v-bind:key="format">
              <router-link :to="$url(`formats/${format.slug}`)" :title="format.title">
                  <div class="menu__item-number">
                      {{ index+1 }}
                  </div>
              </router-link>
          </li>
      </ul>
    </div>

</div>
</template>


<script>
import Close from '@/components/CloseComponent.vue';
import SectionAgenda from '@/components/format/SectionAgendaComponent.vue';
import SectionDescription from '@/components/format/SectionDescriptionComponent.vue';
import SectionGallery from '@/components/format/SectionGalleryComponent.vue';


export default {
    name: 'Formats',
    components: {
        Close,
        SectionAgenda,
        SectionDescription,
        SectionGallery
    },
    computed(){
        return {

        }
    },
    data(){
        return {
            isScrolled: false,
            loaded: false,
            sections: ['.section__agenda', '.section__description', '.section__gallery'],
            currentSection: null,
            previousScroll: 0
        }
    },
    methods: {
        setFormat(slug){
            this.$store.commit('setCurrentFormat', slug);
        },
        handleScroll: function(){
            this.isScrolled = (window.scrollY > 30) ? true : false;
            this.previousScroll = (window.scrollY == 0) ? 0 : this.previousScroll;
            // for (let section of this.sections) {
            //     var element = document.querySelector(section);
            //     if(element){
            //         var position = element.getBoundingClientRect();
            //         if((position.top*-1+window.innerHeight) > 50) {
            //             this.currentSection = element;
            //         }
            //     }
            // }
        },
        getMainColor(color){
            return {
                '--main-color': color,
                '--circle-gradient': `radial-gradient(ellipse at center, ${color} 0%, ${color} 53%, rgba(0, 0, 0, 0) 70.3%)`
            }
        }
    },
    mounted() {
        window.$("body").removeClass().addClass('formats');
        this.unwatch = this.$store.watch(
            (state, getters) => getters.getFormat,
            (newValue) => {
                if(newValue){
                    this.$setHeaderData({title: newValue.title });
                }
            }
        );
        this.setFormat(this.$route.params.slug);
        window.addEventListener('scroll', this.handleScroll);
        this.loaded = true;
    },
    beforeUnmount() {
        this.unwatch();
    },
    unmounted: function () {
        window.removeEventListener('scroll', this.handleScroll);
        this.loaded = false;
        document.body.classList.add('formats');
    },
    watch: {
        $route(to) {
            this.setFormat(to.params.slug);
            this.loaded = false;
            var _this = this;
            setTimeout(function(){
                _this.loaded = true;
            });
        },
        currentSection: function(newValue, oldValue){
            console.log(document.body.getBoundingClientRect().top);
            if(window.$(window).width() >= 768 && this.previousScroll > document.body.getBoundingClientRect().top){
                this.previousScroll = document.body.getBoundingClientRect().top;
                window.$([document.documentElement, document.body]).animate({
                    scrollTop: window.$(newValue).offset().top
                }, 1000);
            }
            console.log("Change slide " + oldValue + " to " +  newValue);
        }
    }
}
</script>
