<template>
    <div class="agenda">
        <table class="agenda__table">
            <template v-for="event in agenda" v-bind:key="event">
              <tr :class="['agenda__table__row' ]" :style="getMainColor(event)">
                  <td @click="$push(`formats/${event.formats[0].slug}`)" v-if="event.date" class="agenda__table__col agenda__table__col-date">
                    <template v-if="event.date.to">
                      {{ event.date.from }} - {{ event.date.to }}
                    </template>
                    <template v-else>
                      {{ event.date.from }}
                    </template>
                  </td>
                  <td @click="$push(`formats/${event.formats[0].slug}`)" class="agenda__table__col agenda__table__col-title">
                    <div>{{ event['title'] }}</div>
                    <div class="mobile">{{ event['category'].join(', ') }}</div>
                  </td>
                  <td @click="$push(`formats/${event.formats[0].slug}`)" class="agenda__table__col agenda__table__col-category">
                    <div>{{ event['category'].join(', ') }}</div>
                  </td>
                  <td class="agenda__table__col agenda__table__col-text">
                      <div v-html="getClipContent(event)"></div>
                      <button class="showMore" @click="showAllContent(event)" v-if="isClipedContent(event['contentText'])">
                          <!-- <span v-if="!event['isCliped']">{{$t("readless")}}</span> -->
                      </button>
                  </td>
              </tr>
              <tr class="agenda__table__row-description">
                <td colspan="3">
                  <div v-html="event.contentText" v-if="!event.isCliped"></div>
                  <button class="showMore" @click="showAllContent(event)" v-if="isClipedContent(event['contentText'])">

                  </button>
                </td>
              </tr>
            </template>
        </table>

    </div>
</template>


<script>
// @ is an alias to /src
import clip from "text-clipper"

export default {
    name: 'Agenda',
    props: {
        agenda: Array()
    },
    components: {

    },
    data(){
        return {
            isCliped: true,
        }
    },
    mounted(){

    },
    methods: {
        getClipContent(event){
            if(event["isCliped"] == undefined){
                event["isCliped"] = true;
            }
            if(event["isCliped"]){
                let clipContent = clip(event.contentText, 200, { html: true, maxLines: 5 });
                return clipContent;
            }else {
                return event.contentText;
            }
        },
        isClipedContent(content){
            let clipContent = clip(content, 200, { html: true, maxLines: 5 });
            return clipContent != content;
        },
        showAllContent(event){
            event["isCliped"] = !event["isCliped"]
        },
        getMainColor(event){
            var color = "";
            if(event.formats){
                if(event.formats.length){
                    color = event.formats[0].color
                }
            }
            return {
                '--main-color': color
            }
        }
    }
}
</script>
