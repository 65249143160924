<template>
    <section class="section section__agenda">
        <Agenda :agenda="$store.getters.getFormat['agenda']" />
    </section>
</template>


<script>
import Agenda from '@/components/AgendaComponent.vue';

export default {
    name: 'SectionAgenda',
    props: {
        agenda: Array()
    },
    components: {
        Agenda,
    },
    data(){
        return {

        }
    },
    mounted(){

    },
}
</script>
