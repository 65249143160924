<template>
    <section class="section section__gallery">
        <splide v-if="show" :options="options">
            <splide-slide v-for="(slide) in $store.getters.getFormat['gallery']" v-bind:key="slide">
                <img :src="slide['image']" :alt="slide['title']" />
                <p>{{ slide['title'] }}</p>
            </splide-slide>
        </splide>
    </section>
</template>


<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
    name: 'SectionGallery',
    props: {

    },
    components: {
        Splide,
        SplideSlide,
    },
    data(){
        return {
            options: {
                rewind : true,
                pagination: false,
                width  : 0,
                gap    : '0rem',
                height: '90vh'
            },
            show: true
        }
    },
    mounted(){

    },
    methods: {

    },
    watch: {
        $route(to, from) {
            this.show = false;
            setTimeout(() => {
                this.show = true;
            });

        }
    }
}
</script>
