<template>
    <section class="section section__description">
        <div class="section__description__content">
            <div class="section__description__content-text" v-html="$store.getters.getFormat['contentText']"></div>
            <div class="section__description__content-side">
                <div class="section__description__content-side-people">
                    <h4 v-if="$store.getters.getFormat['people'].length">{{ $t("Involved") }}</h4>
                    <ul>
                        <li @click="$refs.circlePopup.setCPerson(person)" v-for="person in $store.getters.getFormat['people']" v-bind:key="person">
                            {{ person.title }}
                        </li>
                    </ul>
                </div>
                <div class="section__description__content-side-sideContentBlock">
                  <template v-for="block in $store.getters.getFormat['sideContentBlock']" v-bind:key="block">
                    <div :class="['sideBlock', `sideBlock__${block.type}`]">
                      <template v-if="block.type == 'lead'">
                        <div v-html="block.content.text"></div>
                      </template>
                      <template v-if="block.type == 'heading'">
                        <h4>{{ block.content.text }}</h4>
                      </template>
                      <template v-if="block.type == 'audios'">
                        <a target="_blank" :href="block.content.audioLink">{{block.content.audioTitle}}</a>
                      </template>
                      <template v-if="block.type == 'pdfs'">
                        <a target="_blank" :href="block.content.PDFLink">{{block.content.PDFTitle}}</a>
                      </template>
                      <template v-if="block.type == 'emails'">
                        <a target="_blank" :href="`mailto: ${block.content.email}`">{{block.content.email}}</a>
                      </template>
                    </div>
                  </template>
                </div>
            </div>
        </div>

        <CirclePopup ref="circlePopup" />

    </section>
</template>


<script>
import CirclePopup from '@/components/CirclePopupComponent.vue';

export default {
    name: 'SectionDescription',
    props: {
    },
    components: {
        CirclePopup
    },
    data(){
        return {

        }
    },
    mounted(){

    },
    methods: {

    }
}
</script>
